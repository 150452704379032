import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './_guards/admin.guard';
import { SigninGuard } from './_guards/signin.guard';
import { AdminLayoutComponent } from './_layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './_layouts/auth-layout/auth-layout.component';



const routes: Routes = [
  {
    path: '', 
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [SigninGuard],
    component: AuthLayoutComponent,
    children: [{
        path: '',
        loadChildren: () => import ('./_layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
    }]
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
    component: AdminLayoutComponent,
    children: [{
          path: '',
          loadChildren: () => import('./_layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
      }]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
