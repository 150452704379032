<div>
  <mat-toolbar>
    <div  fxShow="true" fxHide.gt-sm="true">
      <button class="mobile-btn" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <a mat-button class="companyName" routerLink="/">
      <span>WeedJoose</span>
    </a>
    <span class="example-spacer"></span>
    <div  fxShow="true" fxHide.gt-sm="true">
      <button class="mobile-btn" mat-icon-button>
        <mat-icon>logout</mat-icon>
      </button>
    </div>
    <div fxShow="true" fxHide.lt-md="true">
      <button
          mat-icon-button
          class="nav-left-icon"
          (click)="dashboard()">
          View Recipes
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="createNew()">
          Create Recipe
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="introduction()">
          Introduction
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="categories()">
          Categories
        </button>
        <span class="example-spacer"></span>
        <button
            mat-icon-button
            class="icon-padding mobile-btn"
            (click)="logout()"
            slot="end">
            <mat-icon>logout</mat-icon>
        </button>
    </div>

  </mat-toolbar>
  <mat-sidenav-container fxFlexFill class="example-container">

    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
      <div fxLayout="column" fxLayoutAlign="start center">
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="dashboard(); sidenav.toggle()">
          View Recipes
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="createNew(); sidenav.toggle()">
          Create Recipe
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="introduction(); sidenav.toggle()">
          Introduction
        </button>
        <button
          mat-icon-button
          class="nav-left-icon"
          (click)="categories(); sidenav.toggle()">
          Categories
        </button>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<!-- <p>
  <mat-toolbar class="app-header">
    <img src="assets/images/WJ_Logo.jpg" alt="Weed Joose Logo" width="55" height="55">
    <div class="header-left">
      <button
        mat-icon-button
        class="nav-left-icon"
        (click)="dashboard()">
        View Recipes
      </button>

      <button
        mat-icon-button
        class="nav-left-icon"
        (click)="createNew()">
        Create Recipe
      </button>

      <button
        mat-icon-button
        class="nav-left-icon"
        (click)="introduction()">
        Introduction
      </button>

      <button
        mat-icon-button
        class="nav-left-icon"
        (click)="categories()">
        Categories
      </button>
    </div>

    <span class="example-spacer"></span>

    <button
        mat-icon-button
        class="icon-padding"
        (click)="logout()">
        Logout
    </button>
  </mat-toolbar>
</p> -->
