import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {


  constructor(
    public router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.checkSession();
  }
  
  checkSession(){
  // this is mainly to handle user returning to website 
  // when session is expired
  this.userService.whoami().then(async (data: any) => {
    if (data.status !== 'success') {
      if(data && data.error && data.error.status === 'fail'){
        // logout to clear locally cached data
        this.logout();
      }
    }
  });
}
  logout(){
    this.userService.logout();
  }

  createNew(){
    this.router.navigate(['/admin/create-new']);
  }
  dashboard(){
    this.router.navigate(['/admin/dashboard']);
  }
  introduction(){
    this.router.navigate(['/admin/introduction']);
  }
  categories(){
    this.router.navigate(['/admin/categories'])
  }

}
