import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl: string;

  constructor(private http: HttpClient) {
      this.baseUrl = `${environment.apiProtocol}://${environment.apiBaseUrl}/`;
  
   }


  get(uri: string, data: any = {}) {
        let _params = new HttpParams();

        for (const property in data) {
            _params = _params.append(property, data[property]);
        }

        return this.http.get(`${this.baseUrl}${uri}`, { params: _params, withCredentials: true });
    }

  post(uri: string, data: any = {}) {
        return this.http.post(`${this.baseUrl}${uri}`, data, { withCredentials: true });
    }

  put(uri: string, data: any = {}) {
      return this.http.put(`${this.baseUrl}${uri}`, data);
  }

  delete(uri: string, data: any = {}) {
      return this.http.delete(`${this.baseUrl}${uri}`, data);
  }
}

