import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})

export class SigninGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.userService.userIsLoggedIn()) {
        this.router.navigate(['/admin/dashboard'], {queryParams: {returnUrl: state.url}});
        return false;
      }
      return true;
  }
}
