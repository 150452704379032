import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  token: string;

  constructor(
    private api: ApiService,
    private router: Router
  ) { }

get status() {
  return !!localStorage.getItem('currentUser');
}
get user() {
  return JSON.parse(localStorage.getItem('currentUser'));
}

setUser(user) {
  localStorage.setItem('currentUser', JSON.stringify(user));

}

deleteUser(){
  delete localStorage.currentUser;
}

userIsLoggedIn() {
  return !!localStorage.getItem('currentUser');
}

login(loginData: any) {
        return new Promise((resolve, reject) => {
            this.api.post(`wapp/login`, loginData)
            .subscribe((res: any) => {
                let user = res.data;
                if (user) {
                    this.setUser(user);
                }
                resolve(res);
            }, (err: any) => {
                resolve(err);
            });
        });
    }

  whoami() {
      return new Promise((resolve, reject) => {
          this.api.get(`wapp/whoami`)
          .subscribe((res: any) => {
              let user = res.data;
              if (user) {
                  this.setUser(user);
              }
              resolve(res);
          }, (err: any) => {
              resolve(err);
          });
      });
  }
  // navigates to sign in page on success
  logout() {
      return new Promise((resolve, reject) => {
          this.api.post(`wapp/logout`)
          .subscribe((res: any) => {
            this.deleteUser();
            this.router.navigate(['/auth/login']);
            resolve(res);
          }, (err: any) => {
            reject(err);
          });
      });

      location.reload();
  }

}
